<template>
    <div>
        <el-form :model="formData" :rules="rules" ref="ruleForm" @submit.prevent.native="checkEnter" class="main-form"
            label-position="left">
            <div class="main-row">
                <div class="col-md-12">
                    <div class="form_input_container">
                        <el-form-item label="Country" prop="country" class="element-item">
                            <el-select :value="formData.country.name" v-model="formData.country" filterable
                                :key="formData.country.name">
                                <el-option v-for="item in countries" :key="item.name" :label="item.name" :value="[
                                    {
                                        name: item.name,
                                        dial_code: item.dial_code,
                                        abbreviation: item.code,
                                    },
                                ]">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; font-size: 13px">{{
                                        item.dial_code
                                    }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form_input_container">
                        <el-form-item label="International Rate" prop="international_rate" class="element-item">
                            <el-input placeholder="Enter Rate" v-model="formData.international_rate"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form_input_container">
                        <el-form-item label="Local Rate" prop="local_rate" class="element-item">
                            <el-input placeholder="Enter Rate" v-model="formData.local_rate"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form_input_container">
                        <el-button style="" type="danger" id="cancelinput" @click="openModal('ruleForm')">
                            Submit
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="modal-conformation" v-if="dialogVisible">
            <el-dialog title="Confirm" :visible.sync="dialogVisible" width="30%">
                <span>Send the following rate</span>
                <div>
                    <div>
                        <code>
               <span  class="header">Country:</span> {{ formData.country[0].name }}
              </code>
                    </div>
                    <div>
                        <code>
                <span class="header">Dial Code:</span>
                {{ formData.country[0].dial_code }}
              </code>
                    </div>
                    <div>
                        <code>
                <span  class="header">Rate:</span>
                 {{ formData.rate }}
              </code>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="sendRates" :loading="buttonloading">Confirm</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import country from "@/helpers/allCountriesCode.json";
import store from '@/state/store.js'
export default {
    data() {
        return {
            countries: country,
            formData: {
                country: [],
                local_rate: "0",
                international_rate: "",
            },
            rules: {
                country: [
                    {
                        required: true,
                        message: "Country is required.",
                        trigger: "blur",
                    },
                ],
                international_rate: [
                    { required: true, message: "Enter a valid rate", trigger: "blur" },
                ],
                local_rate: [
                    { required: true, message: "Enter a valid rate", trigger: "blur" },
                ],
            },
            dialogVisible: false,
            buttonloading: false,
        };
    },
    methods: {
        openModal(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.dialogVisible = true;
                } else {
                    return false;
                }
            });
        },
        sendRates() {
            this.buttonloading = true
            let payload = {
                country_name: this.formData.country[0].name,
                country_code: this.formData.country[0].dial_code,
                local_rate: this.formData.local_rate,
                international_rate: this.formData.international_rate,
                country_abbreviation: this.formData.country[0].abbreviation,
            }
            store.dispatch('admin/international/sendInternationalRate', payload).then((response) => {
                if (response.status === 200) {
                    this.$notify({
                        message: 'Rate sent successfully',
                        type: 'success'
                    })
                    this.buttonloading = false
                    this.dialogVisible = false
                    this.$refs['ruleForm'].resetFields()
                    this.$emit("refreshRatesTable")
                }
            }).catch((error) => {
                this.$notify({
                    message: 'Error sending rate',
                    type: 'error'
                })
            }).finally(() => {
                this.buttonloading = false
            })
        }
    },
  
};
</script>
<style scoped>
.form_input_container * {
    display: flex;
    flex-direction: column;
}

.form_input_container {
    width: 60%;
}

.element-item {
    margin: 2rem auto;
}

.header {
    font-weight: bold;
    color: #000;
}
</style>
