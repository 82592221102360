<template>
  <div>
    <h6>Approve International Sender IDs</h6>
    <div class="main-table">
      <el-table :data="internationalTemplates" v-loading="tableLoading" height="60vh">
        <el-table-column label="Sender ID" prop="sender_id">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sender_id.sender_id }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Phone Number" prop="user.phone"></el-table-column>
        <el-table-column label="Content" prop="body"></el-table-column>
        <el-table-column label="Assigned Rate">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.sender_id.type == 0">Unset</span>
              <span v-if="scope.row.sender_id.type == 1">Local</span>
              <span v-if="scope.row.sender_id.type == 2">International</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Selected Countries" prop="selected_countries">
        </el-table-column>
        <el-table-column label="Approved By" prop="approved_by.name">
        </el-table-column>
        <el-table-column label="Date" prop="created_at" width="120">
          <template slot-scope="scope">
            <div>
              {{ moment(scope.row.created_at).format("ll") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Status" prop="status" :filters="[
          { text: 'Pending', value: 0 },
          { text: 'Approved', value: 1 },
          { text: 'Rejected', value: 2 },
          { text: 'Awaiting Third Party', value: 3 },
        ]" :filter-method="filterTemplate" filter-placement="bottom-end">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status == 0" style="color: #f7921c">Pending</span>
              <span v-else-if="scope.row.status == 1" style="color: #36d962">Approved</span>
              <span v-else-if="scope.row.status == 3" style="color: #563df6">Awaiting Third Party</span>
              <span v-else style="color: #f72f24">Rejected</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template slot-scope="scope">
            <div>
              <el-dropdown trigger="click" @command="ApproveRejectTemplate">
                <el-button id="__edit">
                  <img src="../../../assets/More Square.svg" alt="img" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ scope, type: 'Approve' }">Approve</el-dropdown-item>
                  <el-dropdown-item :command="{ scope, type: 'Reject' }">Reject</el-dropdown-item>
                  <el-dropdown-item :command="{ scope, type: 'Await Third Party' }">Await Third Party
                    Approval</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="col-md-12" style="text-align: center; margin-top: 2em">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- Add dialog box for confirmation -->
    <el-dialog title="Select Rate Type" :visible.sync="dialogVisible">
      <!-- <span>Are you sure you want to <span :class="[type == 'Approve' ? 'approved' : 'rejected']">{{
                type
            }}</span>
                this
                template?</span> -->
      <span>
        <el-form :rules="form_rules" ref="ruleForm" :model="rate_type" class="main-form" label-position="left">
          <el-form-item label="Rate Type" prop="rate_type">
            <el-select v-model="rate_type.rate_type">
              <el-option label="Local" value="1"></el-option>
              <el-option label="International" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>

        <el-button type="primary" @click="sendData('ruleForm')" :loading="buttonLoading">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="confirmationModal" title="Confirmation">
      <span>Are you sure you want to
        <span :class="[type == 'Approve' ? 'approved' : 'rejected']">{{
          type
        }}</span>
        this template?</span>
      <!-- Heading -->
      <div class="template-details__heading">
        <h6>Template Details</h6>
      </div>
      <div class="template-details">
        <div class="template-details__item">
          <strong class="template-details__item__label">Sender ID: </strong>
          <span class="template-details__item__value">
            {{ userSenderID }}
          </span>
        </div>
        <div class="template-details__item">
          <strong class="template-details__item__label">Content: </strong>
          <span class="template-details__item__value">{{ payload.body }}</span>
        </div>
        <div class="template-details__item">
          <strong class="template-details__item__label">Assigned Rate: </strong>
          <span class="template-details__item__value">
            <span v-if="rate_type.rate_type == 0">Unset</span>
            <span v-if="rate_type.rate_type == 1">Local</span>
            <span v-if="rate_type.rate_type == 2">International</span>
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmationModal = false">Cancel</el-button>
        <el-button type="primary" @click="confirmData" :loading="confirmationLoading">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- Awaiting third party modal -->
    <el-dialog :visible.sync="awaitThirdParty" title="Confirmation">
      <span>Are you sure you want to set this template to:
        <span :class="[type == 'Approve' ? 'approved' : 'rejected']">Awaiting third party approval</span>
      </span>
      <!-- Heading -->
      <div class="template-details__heading">
        <h6>Template Details</h6>
      </div>
      <div class="template-details">
        <div class="template-details__item">
          <strong class="template-details__item__label">Sender ID: </strong>
          <span class="template-details__item__value">
            {{ userSenderID }}
          </span>
        </div>
        <div class="template-details__item">
          <strong class="template-details__item__label">Content: </strong>
          <span class="template-details__item__value">{{ payload.body }}</span>
        </div>
        <div class="template-details__item">
          <strong class="template-details__item__label">Assigned Rate: </strong>
          <span class="template-details__item__value">
            <span v-if="rate_type.rate_type == 0">Unset</span>
            <span v-if="rate_type.rate_type == 1">Local</span>
            <span v-if="rate_type.rate_type == 2">International</span>
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="awaitThirdParty = false">Cancel</el-button>
        <el-button type="primary" @click="confirmData" :loading="confirmationLoading">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import Storage from "../../../services/storage";



export default {
  data() {
    return {
      dialogVisible: false,
      payload: {},
      type: "",
      buttonLoading: false,
      tableLoading: false,
      rate_type: {
        rate_type: "",
      },
      form_rules: {
        rate_type: [
          {
            required: true,
            message: "Please select rate type",
            trigger: "change",
          },
        ],
      },
      confirmationModal: false,
      confirmationLoading: false,
      awaitThirdParty: false,
      page: 1,
      pageSize: 20,
      total: 0,
      storage: new Storage(),
    };
  },
  computed: {
    internationalTemplates() {
      return this.$store.getters[
        "admin/international/internationalTemplates"
      ].slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
    userSenderID() {
      if (this.payload.sender_id) {
        return this.payload.sender_id.sender_id
          ? this.payload.sender_id.sender_id
          : "";
      }
      // return this.payload.sender_id.sender_id ? this.payload.sender_id.sender_id : ''
    },
  },
  methods: {
    ApproveRejectTemplate({ scope, type }) {
      this.payload = {
        ...scope.row,
        approve: type == "Approve" ? 1 : type === "Await Third Party" ? 3 : 2,
      };
      this.type = type;
      if (type == "Approve") {
        this.dialogVisible = true;
      } else if (type == "Await Third Party") {
        this.awaitThirdParty = true;
      }
      else {
        this.confirmationModal = true;
      }
    },
    sendData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.buttonLoading = true
          this.confirmationModal = true;
          // validate form
        } else {
          this.buttonLoading = false;
          return false;
        }
      });
    },
    confirmData() {
      let finalPayload = JSON.parse(JSON.stringify(this.payload));
      finalPayload.sender_id.type = this.rate_type.rate_type;

      const { created_at, updated_at, ...rest } = finalPayload
      let payloadToBeSent = {
        ...rest,
        approved_by: this.storage.getSavedState('auth.admin').user_id
      }

      this.confirmationLoading = true;
      this.$store
        .dispatch("admin/international/ApproveOrReject", payloadToBeSent)
        .then(() => {
          this.dialogVisible = false;
          this.buttonLoading = false;
          this.$message({
            type: "success",
            message: `Template has been toggled successfully!`,
          });

          this.refetchData();
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.response.data.message,
          });
          this.refetchData();
        })
        .finally(() => {
          this.confirmationModal = false;
          this.buttonLoading = false;
          this.confirmationLoading = false;
          this.awaitThirdParty = false;
        });
    },
    refetchData() {
      this.tableLoading = true;
      this.$store
        .dispatch("admin/international/getInternationalTemplates")
        .then((data) => { })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    filterTemplate(value, row) {
      return row.status === value;
    },
  },
  mounted() {
    this.tableLoading = true;
    this.$store
      .dispatch("admin/international/getInternationalTemplates")
      .then((data) => {
        this.total = data.data.length;
      })
      .finally(() => {
        this.tableLoading = false;
      });
  },
};
</script>
<style scoped>
.approved {
  color: #36d962;
}

.rejected {
  color: red;
}

.main-form {
  display: block;
  width: 50%;
}

.main-form * {
  display: flex;
  flex-direction: column;
}

.template-details__heading {
  margin-top: 1rem;
  font-style: italic;
  font-weight: 900;
}
</style>
