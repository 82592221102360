<template>
    <div>
        <!-- Tabs -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="Add Rates" name="first">
                <div class="main-container">
                    <div class="left-side">
                        <p>Add Rates</p>
                        <addRate @refreshRatesTable="callRefreshTable" />
                    </div>
                    <div class="vertical-row"></div>
                    <div class="right-side">
                        <p>View Rates</p>
                        <getRates :propKey=propKey> </getRates>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Approve Templates" name="second">
                <ApproveInternationalTemplates></ApproveInternationalTemplates>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>
<script>
import addRate from './addRate.vue'
import getRates from './getRates.vue'
import ApproveInternationalTemplates from './ApproveInternationalTemplates.vue'
export default {
    components: {
        addRate,
        getRates,
        ApproveInternationalTemplates
    },
    data() {
        return {
            propKey: 0,
            activeName: 'first'
        }
    },
    methods: {
        callRefreshTable() {
            return this.propKey += 1
        },
        handleClick(tab, event) {

        }
    },
}
</script>
<style scoped>
.main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.left-side {
    width: 40%;
}

.right-side {
    width: 100%;
}

.vertical-row {
    border-left: 1px solid #ebeef5;
    height: 80vh;
    width: 2px;
    width: 5%;
}
</style>