<template>
    <div>
        <div class="search-box">
            <el-input v-model="search" placeholder="Search for country"></el-input>
        </div>
        <div class="table-container">
            <el-table :data="displayTable" height="50vh" v-loading="isLoading" :key="mainPropKey">
                <el-table-column prop="country_name" label="Country"></el-table-column>
                <el-table-column prop="country_abbreviation" label="Abbreviation"></el-table-column>
                <el-table-column prop="country_code" label="Dial Code"></el-table-column>
                <el-table-column prop="international_rate" label="International Rate">
                    <template slot-scope="scope">
                        <span style="color:blue">{{ scope.row.international_rate }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="local_rate" label="Local Rate">
                    <template slot-scope="scope">
                        <span style="color:red">{{ scope.row.local_rate }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="updated_at" label="Updated On">
                    <template slot-scope="scope">
                        <span>{{ moment(scope.row.updated_at) }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="col-md-12" style="text-align: center; margin-top:2em;">
                <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                    :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/state/store.js'
export default {
    data() {
        return {
            internationalRates: [],
            search: '',
            loadingTable: false,
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    computed: {
        tableData() {
            return this.internationalRates.filter((row) => {
                return row.country_name.toLowerCase().includes(this.search.toLowerCase()) || row.country_code.toLowerCase().includes(this.search.toLowerCase()) || row.international_rate.toLowerCase().includes(this.search.toLowerCase()) || row.local_rate.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        displayTable() {
            return this.tableData.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
        },
        isLoading() {
            return this.loadingTable
        },
        mainPropKey() {
            this.fetchData()
            return this.$props.propKey
        }
    },
    mounted() {
        this.fetchData()
    },
    props: {
        propKey: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        fetchData() {
            this.loadingTable = true
            store.dispatch('admin/international/getInternationalRate').then((response) => {
                if (response.status === 200) {
                    this.internationalRates = response.data
                    this.loadingTable = false
                }
            }).catch((error) => {
                this.loadingTable = false
                this, $notify({
                    title: 'Error',
                    message: 'Error fetching data',
                    type: 'error',
                })
            })
        },
        handleCurrentChange(val) {
            this.page = val;
        },
    }
}
</script>
<style scoped>
.search-box {
    margin-bottom: 1rem;
    width: 50%;
}
</style>